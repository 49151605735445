import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Setup } from "src/app/shared/models/setup.model";
import { ComboService } from "src/app/shared/services/combo.service";
import { ModalService } from "src/app/shared/services/modal.service";
import { SetupService } from "src/app/shared/services/setup.service";
import { ToastService, TOAST_STATE } from "../../shared/services/toast.service";
import { first } from "rxjs";
import { Market } from "src/app/shared/models/market.model";
import { MarketSelectionService } from "src/app/shared/services/market-selection.service";
import { Combo } from "src/app/shared/models/combo.model";
import { UserService } from "src/app/shared/services/user.service";

@Component({
  selector: "app-real-time-management-setup",
  templateUrl: "./real-time-management-setup.component.html",
  styleUrls: ["./real-time-management-setup.component.scss"],
})
export class RealTimeManagementSetupComponent implements OnInit {
  public selectedMarket: Market | undefined;
  public selectedMarketOld: Market | undefined;

  public showTable: boolean = false;

  public formSearch: FormGroup = this.fb.group({
    setup: [""],
  });

  public formSetup: FormGroup = this.fb.group({
    setupItemId: [""],
    parentId: [null],
    description: [""],
  });

  public selectedId: any;
  public selectedName: any;
  public setupItemList: Array<any> = [];
  selectedSetupItemModal: any;
  selectedSetupItem: any;

  hasParentId: boolean = false;

  public resultSetup: Array<any> = [];

  // Combos
  public comboSetup: Array<Combo> = [];
  private comboSegment: Array<Combo> = [];
  private comboIssueType: Array<Combo> = [];

  public comboModalSetup: Array<Combo> = [];
  public comboModalAux: Array<Combo> = [];
  public comboModalIssueType: Array<Combo> = [];
  public comboModalSegment: Array<Combo> = [];

  // MODAL
  private selectedModal: any;
  public modalTitle: string = "";
  public setupItemModal: any;

  public eStateSetupItem: string = "";
  public eMsgSetupItem: string = "";

  public eStateIssueType: string = "";
  public eMsgIssueType: string = "";
  public labelParentId: string = "";

  public eStateDesc: string = "";
  public eMsgDescShow: boolean = false;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    private toast: ToastService,
    private userService: UserService,
    public modalService: ModalService,
    private comboService: ComboService,
    private setupService: SetupService,
    private marketSelectionService: MarketSelectionService
  ) {}

  ngOnInit(): void {
    this.marketSelectionService.selectedMarket$
      .pipe(first())
      .subscribe((market) => {
        this.selectedMarket = market;
      });
    this.marketSelectionService.selectedMarket$.subscribe((market) => {
      this.selectedMarket = market;
      this.marketSelectionService.onChangeMarket("real-time-management/setup");
    });

    if (this.selectedMarket!.id > 0) {
      this.loadComboSetup();
      this.loadComboIssueType();
      this.loadComboFunction();
    } else {
      this.toast.showToast(TOAST_STATE.information, "Select a market");
    }
  }

  //#region Load Combos

  private loadComboSetup(): void {
    this.comboService.getSetupItem().subscribe({
      next: (result) => {
        if (result.length > 0) {
          this.comboSetup = result;
          this.comboModalSetup = [...this.comboSetup];
        }
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  private loadComboIssueType(): void {
    this.comboService
      .getSetupIssueTypeDetail(this.selectedMarket!.id, "RTM")
      .subscribe({
        next: (result) => {
          if (result.length > 0) {
            this.comboIssueType = result;
            this.comboModalIssueType = [...this.comboIssueType];
          }
        },
        error: (e) => {
          console.log(e);
        },
      });
  }

  private loadComboFunction() {
    this.comboService
      .getSetupItemType(this.selectedMarket!.id, "RTM", "Segment")
      .subscribe({
        next: (result) => {
          if (result.length > 0) {
            this.comboSegment = result;
            this.comboModalSegment = [...this.comboSegment];
          }
        },
        error: (e) => {
          console.log(e);
        },
      });
  }

  //#endregion

  public onChangeSetup(event: any) {
    const selectedValue = event.value;
    const item = this.comboSetup.find((x) => x.id == selectedValue);
    
    this.selectedName = item ? item.label : "All";
    this.selectedId = item ? item.id : null;
    this.selectedSetupItemModal = selectedValue;
    this.setupItemModal = this.selectedName;

    this.verifySetupParentId(this.selectedName);
  }

  public search() {
    if (this.selectedMarket && this.selectedMarket.id > 0) {
      this.setupService
        .getAll(
          this.selectedMarket!.id,
          "RTM",
          this.formSearch.value.setup
        )
        .subscribe({
          next: (result) => {
            this.resultSetup = [];
            if (result && result.length > 0) {
              result.forEach((i) => {
                this.resultSetup.push({
                  id: i.id,
                  description: i.description,
                  parentDesc: i.parentDesc,
                });
              });
            }

            this.showTable = true;
          },
          error: (e) => {
            this.toast.showToast(TOAST_STATE.error, e.error);
            this.showTable = false;
          },
        });
    } else {
      this.toast.showToast(
        TOAST_STATE.information,
        "Select a market before search"
      );
    }
  }

  public openModalSetup(): void {
    this.configModal();
    this.modalTitle = "New RTM Setup";
    this.selectedModal = document.getElementById("form-modal");
    this.selectedModal.openModal();
  }

  private configModal(): void {
    this.formSetup.patchValue({
      setupItemId: this.selectedId ? this.selectedId : "",
      parentId: null,
      description: "",
    });
    
    const item = this.comboSetup.find((x) => x.id == this.selectedId);
    if (item) {
      this.verifySetupParentId(item.label);
    } else {
      this.verifySetupParentId("");
    }
  }

  //#region Modal

  public onChangeModalSetup(event: any): void {
    const selectedValue = event.value;
    const item = this.comboModalSetup.find((x) => x.id == selectedValue);
    
    if (item) {
      this.setupItemModal = item.label;
      this.formSetup.patchValue({
        setupItemId: item.id,
        parentId: null,
      });

      this.verifySetupParentId(item.label);
    } else {
      this.verifySetupParentId("");
    }
  }

  public onChangeModalAux(event: any): void {
    const selectedValue = event.value;
    const item = this.comboModalAux.find((x) => x.id == selectedValue);

    if (item) {
      this.formSetup.patchValue({
        parentId: item.id,
      });
    }
  }

  private verifySetupParentId(item: string): void {
    this.hasParentId = item === "Detail" || item === "Function";

    if (this.hasParentId) {
      this.comboModalAux = [];

      if (item === "Detail") {
        this.labelParentId = "Issue Type:";
        this.comboModalAux = [...this.comboIssueType];
      }

      if (item === "Function") {
        this.labelParentId = "Segment:";
        this.comboModalAux = [...this.comboSegment];
      }
    } else {
      this.labelParentId = "";
      this.comboModalAux = [];
    }
  }

  validate(): boolean {
    let result = true;

    if (!this.formSetup.value.setupItemId) {
      this.eStateSetupItem = "error";
      this.eMsgSetupItem = "Select a Setup Item";
      result = false;
    } else {
      this.eStateSetupItem = "";
      this.eMsgSetupItem = "";
    }

    if (this.setupItemModal == "Detail" && !this.formSetup.value.parentId) {
      this.eStateIssueType = "error";
      this.eMsgIssueType = "Select a Issue Type";
      result = false;
    } else {
      this.eStateIssueType = "";
      this.eMsgIssueType = "";
    }

    if (this.formSetup.value.description.length < 3) {
      this.eStateDesc = "error";
      this.eMsgDescShow = true;
      result = false;
    } else {
      this.eStateDesc = "";
      this.eMsgDescShow = false;
    }

    return result;
  }

  public saveSetupRtm(): void {
    if (this.validate()) {
      var user = "";
      this.userService.userName$.subscribe(userName => {
        user = userName;
      });
      
      const setupObj: Setup = {
        id: null,
        description: this.formSetup.value.description,
        acronym: "",
        marketId: this.selectedMarket!.id,
        setupItemId: this.formSetup.value.setupItemId,
        parentId: this.formSetup.value.parentId,
        parentDesc: "",
        project: "RTM",
        createdUser: user,
      };

      this.setupService.insert(setupObj).subscribe({
        next: (result) => {
          this.formSearch.patchValue({
            selectSetup: this.formSetup.get("setupItemId")!.value,
          });
          this.selectedModal.closeModal();
          this.toast.showToast(TOAST_STATE.success, "Setup saved.");
          this.search();
        },
        error: (e) => {
          this.toast.showToast(
            TOAST_STATE.error,
            `Can not save. Error: ${e.error}.`
          );
        },
      });
    }
  }

  //#endregion

  //#region Delete

  public delete(id: number): void {
    if (id) {
      this.setupService.delete(id).subscribe({
        next: (result) => {
          this.toast.showToast(TOAST_STATE.success, "Setup deleted");
          this.resultSetup = this.resultSetup.filter((item) => item.id !== id);
        },
        error: (e) => {
          this.toast.showToast(TOAST_STATE.error, e.error);
        },
      });
    }
  }

  //#endregion
}
