<tds-breadcrumbs>
    <tds-breadcrumb>
        <a href="/change-point-management">Change Point Management</a>
    </tds-breadcrumb>
    <tds-breadcrumb current>
        <a href="#">Search</a>
    </tds-breadcrumb>
</tds-breadcrumbs>

<br>

<div class="sdds-card">

    <div class="sdds-card-body">
        <form [formGroup]="searchCpmForm">

            <div class="sdds-row">
                <div class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-6">
                    <sdds-dropdown (selectOption)="handleChange($event, 'segmentId')"
                        [placeholder]="getDropdownLabel(filtro.segmentId, 'segment')" label="Segment"
                        label-position="outside">
                        <sdds-dropdown-option value="All">All</sdds-dropdown-option>
                        <sdds-dropdown-option *ngFor="let item of segmentList" value="{{item.id}}"
                            [selected]="filtro.segmentId == item.id ? true : false">{{item.label}}</sdds-dropdown-option>
                    </sdds-dropdown>
                </div>
                <div class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-6">
                    <sdds-dropdown (selectOption)="handleChange($event, 'functionId')"
                        [placeholder]="getDropdownLabel(filtro.functionId, 'function')" label="Function Area"
                        label-position="outside">
                        <sdds-dropdown-option value="All">All</sdds-dropdown-option>
                        <sdds-dropdown-option *ngFor="let item of functionListAux" value="{{item.id}}"
                            [selected]="filtro.functionId == item.id ? true : false">{{item.label}}</sdds-dropdown-option>
                    </sdds-dropdown>
                </div>
            </div>

            <div class="sdds-row">
                <div class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-6">
                    <sdds-dropdown (selectOption)="handleChange($event, 'issueTypeId')"
                        [placeholder]="getDropdownLabel(filtro.issueTypeId, 'item')" label="Item Category"
                        label-position="outside">
                        <sdds-dropdown-option value="All">All</sdds-dropdown-option>
                        <sdds-dropdown-option *ngFor="let item of issueTypeList" value="{{item.id}}"
                            [selected]="filtro.issueTypeId == item.id ? true : false">{{item.label}}</sdds-dropdown-option>
                    </sdds-dropdown>
                </div>
                <div class="sdds-col-xs-12 sdds-col-sm-12 sdds-col-md-6">
                    <sdds-dropdown (selectOption)="handleChange($event, 'detailId')"
                        [placeholder]="getDropdownLabel(filtro.detailId, 'details')" id="sdds-dropdown-option-disabled"
                        label="Issue Origin/Details" label-position="outside">
                        <sdds-dropdown-option value="All">All</sdds-dropdown-option>
                        <sdds-dropdown-option *ngFor="let item of detailsListAux" value="{{item.id}}"
                            [selected]="filtro.detailId == item.id ? true : false">{{item.label}}</sdds-dropdown-option>
                    </sdds-dropdown>
                </div>
            </div>

            <div class="sdds-row">
                <div class="sdds-col-md-6" style="width:100%;">
                    <sdds-datetime ngDefaultControl type="date" formControlName="periodFrom">
                        <span slot="sdds-label">Period From</span>
                    </sdds-datetime>
                </div>
                <div class="sdds-col-md-6" style="width:100%">
                    <sdds-datetime ngDefaultControl type="date" formControlName="periodTo">
                        <span slot="sdds-label">Period To</span>
                    </sdds-datetime>
                </div>
            </div>

        </form>
    </div>

    <div class="sdds-card-footer">
        <button class="sdds-btn sdds-btn-sm sdds-btn-primary" (click)="search()">Search</button>
    </div>

</div>

<!-- RESULT TABLE -->
<div class="sdds-card" *ngIf="showTable">

    <div class="sdds-card-header">
        <h3 class="sdds-card-headline">Search Result</h3>
    </div>

    <div class="sdds-divider-light-border-top"></div>

    <div class="sdds-card-body">

        <div style="overflow-x: auto;">
            <table class="sdds-table sdds-table--divider">
                <thead class="sdds-table__header">
                    <tr class="sdds-table__row">
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Id</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Description</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Function</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Chassis Number</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Batch Number</th>
                        <th class="sdds-table__header-cell sdds-u-pl2 sdds-u-pr2">Actions</th>
                    </tr>
                </thead>
                <tbody class="sdds-table__body">
                    <tr class="sdds-table__row" *ngFor="let item of cpmList">
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.id}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.description}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.function}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.chassis}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2">{{item.batch}}</td>
                        <td class="sdds-table__body-cell sdds-u-pl2 sdds-u-pr2 action-buttons">
                            <img src="assets\icons\edit.svg" title="Edit" (click)="edit(item.id)">
                            <img src="assets\icons\trash.svg" title="Delete"
                                (click)="modalService.openConfirmation(item.id, delete.bind(this))">
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>

<!-- DELETE MODAL -->
<app-confirmation-modal size="xs" modalId="deletion-modal" title="Delete Change Point Management"
    message="Are you sure you want to delete this CPM item?">
</app-confirmation-modal>