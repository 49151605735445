import { Component } from '@angular/core';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent {

  constructor(
    public toast: ToastService
    ) { }
  
  toastClass: string[] = [];  
  toastMessage: string = '';  
  showsToast: boolean = false; 

  dismiss(): void {    
    this.toast.dismissToast();  
  }

}