import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

export const TOAST_STATE = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    information: 'information'
};


@Injectable({
    providedIn: 'root'
})


export class ToastService {

    public showsToast$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public toastMessage$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public toastState$: BehaviorSubject<string> = new BehaviorSubject<string>('');

    constructor() { }
    showToast(toastState: string, toastMsg: string): void {
        // Observables use '.next()' to indicate what they want done with observable    
        // This will update the toastState to the toastState passed into the function    ﻿
        this.toastState$.next(toastState);

        // This updates the toastMessage to the toastMsg passed into the function    ﻿
        this.toastMessage$.next(toastMsg);

        // This will update the showsToast trigger to 'true'
        this.showsToast$.next(true);

        setTimeout(t => this.dismissToast(), 5000);
    }

    dismissToast(): void {
        this.showsToast$.next(false);
    }
}