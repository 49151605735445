import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Observable } from "rxjs";
import { RtmViewModel } from "../models/rtm";
import { RealTimeManagement } from "../models/real-time-management";
import { RealTimeManagementFilters } from "../models/real-time-management-filters.model";

@Injectable({
  providedIn: "root",
})
export class RealTimeManagementService {
  private url: string = environment.apiUrl + "/RealTimeManagement";

  constructor(private httpClient: HttpClient) {}

  public getById(id: number) {
    return this.httpClient.get<RealTimeManagement>(`${this.url}/${id}`);
  }

  public getAllByMarket(marketId: number) {
    return this.httpClient.get<RtmViewModel[]>(
      `${this.url}/getAll/marketid=${marketId}`
    );
  }

  public search(filters: RealTimeManagementFilters) {
    return this.httpClient.post<RtmViewModel[]>(`${this.url}/search`, filters);
  }

  public save(data: RealTimeManagement) {
    return this.httpClient.post(`${this.url}/save`, data);
  }

  public delete(id: number) {
    return this.httpClient.delete(`${this.url}/delete?id=${id}`);
  }
}
