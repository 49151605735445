import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Setup } from '../models/setup.model';

@Injectable({
  providedIn: 'root'
})
export class SetupService {

  private apiUrl = environment.apiUrl + "/Setup" ;
  
  constructor(
    private httpClient: HttpClient
  ) { }

  getById(id: number) {
    return this.httpClient.get(`${this.apiUrl}/${id}`);
  }

  getAll(marketId: number, project: string, setupItemId: number | null) {
    
    let url = `${this.apiUrl}/getAll/${marketId}?project=${project}`;

    if (setupItemId) {
      url += `&setupItemId=${setupItemId}`;
    }

    return this.httpClient.get<Setup[]>(url);
  }

  insert(entity: Setup) {
    return this.httpClient.post(`${this.apiUrl}/insert`, entity);
  }

  update(entity: Setup) {
    return this.httpClient.put(`${this.apiUrl}/update`, entity);
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.apiUrl}/${id}`);
  }

}